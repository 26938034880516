// The file contains single style css class, which describe exactly the style (analog to Tailwind Css)

// Flex
.display-flex {
	display: flex;
}
.align-items-center {
	align-items: center;
}
.align-items-end {
	align-items: flex-end;
}
.align-self-end {
	align-self: flex-end;
}
.align-self-center {
	align-self: center;
}
.flex-wrap-wrap {
	flex-wrap: wrap;
}
.flex-direction-column {
	flex-direction: column;
}
.flex-1 {
	flex: 1;
}
.justify-content-space-between {
	justify-content: space-between;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-flex-end {
	justify-content: flex-end;
}
.gap-groupui-spacing-1 {
	gap: var(--groupui-spacing-1);
}
.gap-groupui-spacing-2 {
	gap: var(--groupui-spacing-2);
}
// 0.5rem
.gap-groupui-spacing-3 {
	gap: var(--groupui-spacing-3);
}
// 0.75rem
.gap-groupui-spacing-4 {
	gap: var(--groupui-spacing-4);
}
// 1rem
.gap-groupui-spacing-5 {
	gap: var(--groupui-spacing-5);
}
// 1.25rem
.gap-groupui-spacing-6 {
	gap: var(--groupui-spacing-6);
}
// 1.5rem = 24px
.gap-groupui-spacing-7 {
	gap: var(--groupui-spacing-7);
}
// 2rem = 32px
.gap-groupui-spacing-8 {
	gap: var(--groupui-spacing-8);
}
// 2.5rem = 40px
.gap-groupui-spacing-9 {
	gap: var(--groupui-spacing-9);
}
// 3rem = 48px
.gap-groupui-spacing-10 {
	gap: var(--groupui-spacing-10);
}
// Grid

.display-grid {
	display: grid;
}
.display-block {
	display: block;
}

// Color

.color-white {
	color: var(--groupui-vwag-color-white);
}
.color-warning {
	color: var(--groupui-vwag-color-signal-orange-800-primary);
}
.color-error {
	color: var(--groupui-vwag-color-signal-red-800-primary);
}
.color-primary {
	color: #1b1e1f;
}
.color-petrol-primary {
	color: var(--groupui-vwag-color-signal-petrol-800-primary);
}

.color-grey {
	color: var(--groupui-vwag-color-grey-800-primary);
}
.color-grey-medium {
	color: var(--groupui-vwag-color-grey-600);
}

.background-color-white {
	background-color: var(--groupui-vwag-color-white);
}

// Dimensions

.height-groupui-spacing-10 {
	height: var(--groupui-spacing-10);
}
.height-fit-content {
	height: fit-content;
}
// Used for header and table info table height
.min-height-64px {
	min-height: 64px;
}
.max-height-50vh {
	max-height: 50vh;
}
.width-initial {
	width: initial;
}
.width-100px {
	width: 100px;
}

.min-width-100px {
	min-width: 100px;
}
.min-width-150px {
	min-width: 150px;
}
.min-width-200px {
	min-width: 200px;
}
.min-width-250px {
	min-width: 250px;
}
.min-width-300px {
	min-width: 300px;
}
.min-width-500px {
	min-width: 500px;
}
.max-width-300px {
	max-width: 300px;
}
.max-width-350px {
	max-width: 350px;
}
.max-width-500px {
	max-width: 500px;
}
.height-100p {
	height: 100%;
}

.height-100vh {
	height: 100vh;
}
.width-100vw {
	width: 100vw;
}

.max-height-calc-100vh-100px {
	max-height: calc(100vh - 100px);
}
.height-2rem {
	height: 2rem;
}
.width-100px {
	width: 100px;
}
.width-125px {
	width: 125px;
}
.width-200px {
	width: 200px;
}
.width-160px {
	width: 160px;
}

.min-width-600px {
	min-width: 600px;
}

.width-100p {
	width: 100%;
}
.width-50p {
	width: 50%;
}
.width-200px {
	width: 200px;
}

// Position

.position-sticky {
	position: sticky;
}
.position-relative {
	position: relative;
}
.position-absolute {
	position: absolute;
}
.top-0 {
	top: 0;
}
.bottom-0 {
	bottom: 0;
}
.right-0 {
	right: 0;
}
.left-0 {
	left: 0;
}
.z-index-1 {
	z-index: 1;
}
// Table info header
.z-index-2 {
	z-index: 2;
}
// Filters
.z-index-3 {
	z-index: 3;
}
// Header
.z-index-4 {
	z-index: 4;
}

// Text

.font-weight-bold {
	font-weight: bold;
}

.text-decoration-none {
	text-decoration: none;
}
.white-space-pre-wrap {
	white-space: pre-wrap;
}
.white-space-no-wrap {
	white-space: nowrap;
}
.hyphens-auto {
	hyphens: auto;
}

.word-break-break-word {
	word-break: break-word;
}
.text-align-left {
	text-align: left;
}
.text-align-center {
	text-align: center;
}
.text-align-right {
	text-align: right;
}
// Padding

.padding-1rem {
	padding: 1rem;
}
.padding-top-1rem {
	padding-top: 1rem;
}
.padding-top-05rem {
	padding-top: 0.5rem;
}
.padding-top-025rem {
	padding-top: 0.25rem;
}
.padding-bottom-2rem {
	padding-bottom: 2rem;
}
.padding-left-05rem {
	padding-left: 0.5rem;
}
.padding-right-05rem {
	padding-right: 0.5rem;
}
.padding-left-1rem {
	padding-left: 1rem;
}
.padding-left-13rem {
	padding-left: 1.3rem;
}
.padding-right-1rem {
	padding-right: 1rem;
}
.padding-right-13rem {
	padding-right: 1.3rem;
}
.padding-inline-075rem {
	padding-inline: 0.75rem;
}
.padding-05rem {
	padding: 0.5rem;
}
.padding-0 {
	padding: 0;
}

.padding-bottom-2rem {
	padding-bottom: 2rem;
}

//Margin

.margin-top-1rem {
	margin-top: 1rem;
}
.margin-left-1rem {
	margin-left: 1rem;
}
.margin-right-1rem {
	margin-right: 1rem;
}
.margin-left-05rem {
	margin-left: 0.5rem;
}
.margin-right-05rem {
	margin-right: 0.5rem;
}

// Overflow

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-initial {
	overflow: initial;
}

// Border

.border-bottom-1px-solid {
	border-bottom: 1px solid;
}
.border-color-grey {
	border-color: var(--groupui-vwag-color-grey-800-primary);
}
.border-color-grey-light {
	border-color: var(--groupui-vwag-color-grey-200-tertiary);
}

// Other Rendering

.opacity-0 {
	opacity: 0;
}
.opacity-1 {
	opacity: 1;
}
.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.display-none {
	display: none;
}
