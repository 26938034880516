/* You can add global styles to this file, and also import other style files */
@import 'normalize.css';
@import '~@group-ui/group-ui-angular/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/vwag/vwag.css';
@import url("https://groupui-css.vwapps.run/staged/groupui.css");
@import 'assets/styles/single-property.scss';
@import 'assets/styles/loading-spinner.scss';
@import 'assets/styles/responsiveness.scss';
@import './app/shared/json-form/styles/json-form.scss';

html,
body {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.required::after {
	content: '*';
	color: var(--groupui-vwag-color-signal-red-800-primary);
}

// Used by groupui-button-state directive
.btn-disabled {
	pointer-events: none;
	opacity: 0.5;
	cursor: not-allowed;
}

.tooltip-indicator:hover {
	text-decoration: underline;
	text-decoration-color: var(--groupui-vwag-color-grey-800-primary);
	text-decoration-style: dotted;
	text-decoration-thickness: from-font;
	text-underline-offset: 3px;
}
// Manually set modal on top
groupui-modal {
	z-index: 10;
}
// Reset to initial, for some some reason in groupui v10 it is set to 600...
groupui-notification {
	z-index: initial;
}

.groupui-label {
	font-weight: bold;
	font-size: 0.875rem;
	color: rgb(76, 83, 86);
}

// Code Mirror

.json-file-code-mirror-error-line {
	text-decoration: underline;
	text-decoration-style: wavy;
	text-decoration-color: red;
}
