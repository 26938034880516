// This file applies styles based on group-ui default break points. Note CSS Variables don't work in @media https://bholmes.dev/blog/alternative-to-css-variable-media-queries/
//
// --groupui-vwag-breakpoint-s: 320px;
// --groupui-vwag-breakpoint-m: 560px;
// --groupui-vwag-breakpoint-l: 960px;
// --groupui-vwag-breakpoint-xl: 1280px;
// --groupui-vwag-breakpoint-xxl: 1620px;

@media screen and (min-width: 560px) {
	.show-lager-equal-than-M {
		display: initial;
	}
	.show-less-than-M {
		display: none;
	}
}

@media screen and (max-width: 560px) {
	.show-lager-equal-than-M {
		display: none;
	}
	.show-less-than-M {
		display: initial;
	}
}
@media screen and (min-width: 960px) {
	.show-lager-equal-than-L {
		display: initial;
	}
	.show-less-than-L {
		display: none;
	}
}

@media screen and (max-width: 960px) {
	.show-lager-equal-than-L {
		display: none;
	}
	.show-less-than-L {
		display: initial;
	}
}
